import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyD-r4g303pe9k0KTh3rAF2k1uMglbZjlcE",
    authDomain: "energywebnfts.firebaseapp.com",
    databaseURL: "https://energywebnfts-default-rtdb.firebaseio.com",
    projectId: "energywebnfts",
    storageBucket: "energywebnfts.appspot.com",
    messagingSenderId: "355811404964",
    appId: "1:355811404964:web:52c869d4269fed54080a31",
    measurementId: "G-5PX3L6B7CN"
};
const app = initializeApp(firebaseConfig);

const app_two = initializeApp({
    databaseURL: "https://energywebnftsportfolios.firebaseio.com",
}, 'appTwo');

export const db = getDatabase(app)
export const dbTwo = getDatabase(app_two)